<template>
  <div id="feedback">
    <div class="topbar">
        <van-nav-bar
            title="问题反馈"
            left-text="返回"
            left-arrow
            @click-left="onClickLeft"
            />
    </div>
    <div class="btnbar">
        <van-button color='#ec4899' @click="addFk" style="width: 80px;color: #ec4899;" type="primary">新增反馈</van-button>
        <!-- <van-button color='#ec4899' style="width: 80px;color: #ec4899;" type="primary">主要按钮</van-button> -->
    </div>
    <div v-if="show" class="feedBody">
        <div class="list" v-for="(item,i) in infoList" :key="i" @click="changeInfo(item)">
            <div class="title">
              <span>反馈日期: </span>  <span style="margin-right: 20px;">{{item.createtime}}</span>
              <van-tag v-if="item.resolvestate==0" type="danger"  style="padding: 0px 5px;">未读</van-tag>
              <van-tag v-if="item.resolvestate==1" type="warning"  style="padding: 0px 5px;">已读</van-tag>
              <van-tag v-if="item.resolvestate==2" type="success" style="padding: 0px 5px">已解决</van-tag>
              <van-tag v-if="item.resolvestate==3" type="primary"  style="padding: 0px 5px;">已采纳</van-tag>
            </div>
            <div class="info">
                <span style="text-align: justify;
                    font-weight: 700;">反馈内容: </span>
                    <span>{{item.desc}}</span>
               
            </div>
        </div>
    </div>
    <div  class="feedBody" v-else>
        <van-empty description="暂无反馈,添加一条吧" />
    </div>
    <div class="feedTip" style="    position: absolute;
    bottom: 40px;">
        <span>温馨提示:</span>
        <span>结合自身使用感受,提出您的宝贵意见,我们将有机会采纳您的意见,做出相应的调整,我们也会对您的账号给予一定的积分或者会员奖励,如果您有任何问题,也可以在此提出,我们也会给予反馈,感谢您的留言~！</span>
    </div>
    <!--  -->
        <!-- 积分购买购买 -->
        <van-action-sheet class="jfPay" v-model="jfpayShow" title="反馈信息">
      <div class="content">
        <!-- <div style="font-size: 36px;
    display: flex;
    justify-content: center;
    align-items: baseline;"><van-icon name="points" />  <span style="font-weight: 700;margin-left: 15px;margin-right: 5px;">20</span><span style="font-size: 20px;font-weight: 700;">积分</span></div>
        <div style="height: 36px;line-height: 36px;font-size: 14px;display: flex;justify-content: space-between;"><span style="color:#a3a3a3">会员权益</span><span style="color:#ec4899">升级会员可免费解锁</span></div>
        <van-divider />
        <div style="height: 36px;line-height: 36px;font-size: 14px;display: flex;justify-content: space-between;"><span style="color:#a3a3a3">茶贴信息</span><span style="color:#000"></span></div>
        <van-divider /> -->
        <van-field
        style="margin-bottom: 20px;"
        v-model="form.description"
        name="反馈内容"
        label="反馈内容"
        placeholder="请填写反馈内容"
        />
        <van-field
        style="margin-bottom: 20px;"
        v-model="form.phone"
        name="联系方式"
        label="联系方式"
        placeholder="请填写联系方式"
        />
        <van-uploader v-if="userInfo.usertype < 10" label="图片上传" :after-read="afterRead" v-model="form.imageList"  :max-count="3" />
        <van-image
        v-if="userInfo.usertype > 10 && form.imageList!='' && form.imageList[0]"
        width="100"
        height="100"
        :src="baseurl+form.imageList[0].split('/').at(-1)"
        />
        <van-image
        v-if="userInfo.usertype > 10 && form.imageList!='' && form.imageList[1]"
        width="100"
        height="100"
        :src="baseurl+form.imageList[1].split('/').at(-1)"
        />
        <van-image
        v-if="userInfo.usertype > 10 && form.imageList!='' && form.imageList[2]"
        width="100"
        height="100"
        :src="baseurl+form.imageList[2].split('/').at(-1)"
        />
        <div class="subPay" style="display: flex;
    justify-content: space-around;">
          <van-button v-if="userInfo.usertype < 10" type="primary" @click="cancel" color="#ec4899" style="height:40px;width:80px">取 消</van-button>
          <van-button v-if="userInfo.usertype < 10" type="primary" @click="submit" color="#ec4899" style="height:40px;width:80px">发 布</van-button>
          <van-button v-if="userInfo.usertype > 10" type="primary" @click="changeState(1)" color="#ec4899" style="height:40px;width:80px">已读</van-button>
          <van-button v-if="userInfo.usertype > 10" type="primary" @click="changeState(2)" color="#ec4899" style="height:40px;width:80px">已解决</van-button>
          <van-button v-if="userInfo.usertype > 10" type="primary" @click="changeState(3)" color="#ec4899" style="height:40px;width:80px">已采纳</van-button>
        </div>
      </div>
    </van-action-sheet>
    
  </div>
</template>

<script>
import { insertFk,getFk,editUserFk } from '../../model/user'
import axios from 'axios'
import { v4 as uuidv4 } from 'uuid';
import { Toast } from 'vant';
export default {
    data() {
        return {
            form: {
                description: "",
                phone: "",
                imageList:[],
                uuid:""
            },
            jfpayShow:false,
            infoList: [],
            show: false,
            files: [],
            baseurl:"http://38.54.111.182/fkImg/"
            
        }
    },
    computed:{
        // ...mapState({
        //     loginStatus : state=>state.user.loginStatus,
        //     userInfo : state=>state.user.userInfo,
        // }),
        loginStatus(){
          return this.$store.state.user.loginStatus
        },
        userInfo(){
          return this.$store.state.user.userInfo
        },
    },
    created() {
        this.getInfo()
    },
    methods: {
        changeState(val) { 
            let data = {
                id: this.form.uuid,
                resolvestate:val
            }
            editUserFk(data).then((res) => { 
                if (res.data.code==0) { 
                    Toast('修改成功');
                    this.jfpayShow = false
                    this.getInfo()
                }
            })
        },
        changeInfo(item) { 
            if (this.$store.state.user.userInfo.username=='admin') { 
                this.jfpayShow = true
                this.form = {
                    description: item.desc,
                    phone: item.phone,
                    imageList:item.imgList != "" ? item.imgList.split(',') : "",
                    uuid:item.id
                }
            }
        },
        getInfo() { 
            let data = {
                username:this.$store.state.user.userInfo.username,
            }
            getFk(data).then((res) => { 
                if (res.data.code == 0) { 
                    if (res.data.data.length>0) { 
                        this.show = true
                    }
                    this.infoList = res.data.data
                }
            })

        },  
        onClickLeft() { 
            this.$router.go(-1)
        },
        addFk() { 
            this.jfpayShow = true
        },
        afterRead(file) { 
            console.log('file', file)
            this.files = file
        },
        submit() { 
            if (this.form.description == '') { 
                Toast('填写反馈内容');
                return
            }
            if (this.form.phone == '') { 
                Toast('填写联系方式');
                return
            }
            if (this.form.imageList.length > 0) {
                let imgList = this.form.imageList.map((item) => {
                    return item.file
                })
                // let data = {
                //     images:imgList
                // }
                let formData = new FormData()
                for (let i = 0; i < imgList.length; i++) {
                    formData.append('images', imgList[i]);  // 注意字段名称 "images"，后端会处理这个字段
                }

                // formData.append('images', ...imgList)
                axios.post('http://38.54.111.182:7890/statistics/uploads', formData, {
       
                }).then((res) => { 
                    console.log('res', res)
                    if (res.data.code == 0) {
                        let imgUrl = res.data.files.map((item) => { 
                            return item.path
                        })
                        this.uuid = uuidv4();
                        let data = {
                            uuid: this.uuid,
                            username: this.$store.state.user.userInfo.username,
                            desc: this.form.description,
                            createtime: this.getNowTime(),
                            imgList: imgUrl,
                            phone: this.form.phone,
                        }
                        console.log('data', data)
                        insertFk(data).then((res) => { 
                            console.log('res12', res)
                            if (res.data.code==0) { 
                                Toast('上传成功');
                                this.getInfo()
                            }
                            this.jfpayShow = false
                            this.form = {
                                description: "",
                                phone: "",
                                imageList:[],
                                uuid:""
                            }
                        })


                    } else { 
                        Toast('上传失败');
                    }   
                });
            } else { 
                this.uuid = uuidv4();
                let data = {
                    uuid: this.uuid,
                    username: this.$store.state.user.userInfo.username,
                    desc: this.form.description,
                    createtime: this.getNowTime(),
                    imgList: [],
                    phone: this.form.phone,
                }
                console.log('data', data)
                insertFk(data).then((res) => { 
                            if (res.data.code==0) { 
                                Toast('上传成功');
                                this.getInfo()
                            }
                            this.jfpayShow = false
                            this.form = {
                                description: "",
                                phone: "",
                                imageList:[],
                                uuid:""
                            }
                        })
            }
            

        },  
        cancel() { 
            this.jfpayShow = false
        },
        //获取当前时间
        getNowTime() {
            var date = new Date();
            //年 getFullYear()：四位数字返回年份
            var year = date.getFullYear();  //getFullYear()代替getYear()
            //月 getMonth()：0 ~ 11
            var month = date.getMonth() + 1;
            //日 getDate()：(1 ~ 31)
            var day = date.getDate();
            // //时 getHours()：(0 ~ 23)
            // var hour = date.getHours();
            // //分 getMinutes()： (0 ~ 59)
            // var minute = date.getMinutes();
            // //秒 getSeconds()：(0 ~ 59)
            // var second = date.getSeconds();

            // var time = '当前时间是：' + year + '-' + this.addZero(month) + '-' + this.addZero(day) + ' ' + this.addZero(hour) + ':' + this.addZero(minute) + ':' + this.addZero(second);
            var time = year + '-' + this.addZero(month) + '-' + this.addZero(day)
            return time;
        },
        //小于10的拼接上0字符串
        addZero(s) {
            return s < 10 ? ('0' + s) : s;
        },
    },

}
</script>

<style scoped>
*{
    margin: 0;
    padding: 0;
}
#feedback{
    width: 100%;
    height: calc(100% - 40px);
    background: #f5f5f5;
}
#feedback .topbar{
    width: 100%;
    height: 60px !important;
}
#feedback .topbar /deep/.van-nav-bar__content{
    height: 60px !important;
}
#feedback .topbar /deep/.van-nav-bar__text{
    color: #ec4899 !important;
}
#feedback .topbar  /deep/.van-nav-bar .van-icon{
    color: #ec4899 !important;
}

.btnbar{
    /* width: 100%; */
    height: 50px;
    padding: 5px 50px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}
.feedBody{
    height: 460px;
    margin: 0 10px 10px;
    /* background: #ec4899; */
    overflow-y: scroll;
}
.feedBody .list{
    font-size: 14px;
    margin: 10px;
    height: 120px;
    background: #fff;
    padding: 10px 10px 10px;
    border-radius: 10px;
    margin-bottom: 12px;
}
.feedBody .list .title{
    height: 20px;
    text-align: justify;
    font-weight: 700;
    line-height: 20px;

}
.feedBody .list .info{
    text-align: justify;
    /* font-weight: 700; */
    /* line-height: 20px; */

    height: 100px;
    /* width: 25%; */
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 24px;

}
.feedTip{
    height: 100px;
    background: #fff;
    margin: 10px;
    border-radius: 10px;
    font-size: 14px;
    text-align: justify;
    padding: 10px;
    color: #ec4899;
    line-height: 20px;
}

/*  */
.jfPay{
  box-sizing: border-box;
  padding: 0 20px;
  height: 55%;
  font-size: 18px;
  color: #ec4899;
}
.jfPay .content{
  text-align: left;
  height: calc(100% - 50px);
  height: 100%;
}
.jfPay .content .subPay{
  text-align: center;
  margin-top: 40px;
}
.jfPay .content .price{
  text-align: center;
  margin-top: 30px;
  font-size: 24px;
}
.jfPay /deep/ .van-action-sheet__header{
  margin-top: 20px;
  margin-bottom: 15px;
  color: #000;
}
/deep/ .van-action-sheet__close{
    right: 12px;
}
</style>